<template>
  <div>
    <button @click="refresh()" class="btn">Actualiser</button>
    <form @submit.prevent="save" class="mt-4">
      <div class="row">
        <div class="col-6">
          <div class="col-12 mb-3">
            <label for="title" class="form-label"> Titre :</label>
            <input
              type="text"
              id="title"
              v-model="project.title"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="photo" class="form-label"> Logo :</label>
            <input
              type="file"
              id="photo"
              @change="handleLogoUpload"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.logoUrl" alt="Uploaded Photo" class="w-50" />
          </div>
          <br />
          1re section
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionATitle" class="form-label">
              Titre de la 1re section :</label
            >
            <input
              type="text"
              id="sectionATitle"
              v-model="project.sectionATitle"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionAImage" class="form-label">
              Image de la 1re section :</label
            >
            <input
              type="file"
              id="sectionAImage"
              @change="handleImageSectionAUpload"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.sectionAImageUrl" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionAFile" class="form-label">
              Fichier de la 1re section :</label
            >
            <input
              type="file"
              id="sectionAFile"
              @change="handleFileSectionAUpload"
              class="form-control"
              accept="*"
            />
          </div>

          <br />
          2ème section
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionBTitle" class="form-label">
              Titre de la 2ème section :</label
            >
            <input
              type="text"
              id="sectionBTitle"
              v-model="project.sectionBTitle"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionBImage" class="form-label">
              Image de la 2ème section :</label
            >
            <input
              type="file"
              id="sectionBImage"
              @change="handleImageSectionBUpload"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.sectionBImageUrl" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionBFile" class="form-label">
              Fichier de la 2ème section :</label
            >
            <input
              type="file"
              id="sectionBFile"
              @change="handleFileSectionBUpload"
              class="form-control"
              accept="*"
            />
          </div>

          <br />
          3ème section
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionCTitle" class="form-label">
              Titre de la 3ème section :</label
            >
            <input
              type="text"
              id="sectionCTitle"
              v-model="project.sectionCTitle"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionCImage" class="form-label">
              Image de la 3ème section :</label
            >
            <input
              type="file"
              id="sectionCImage"
              @change="handleImageSectionCUpload"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.sectionCImageUrl" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionCFile" class="form-label">
              Fichier de la 3ème section :</label
            >
            <input
              type="file"
              id="sectionCFile"
              @change="handleFileSectionCUpload"
              class="form-control"
              accept="*"
            />
          </div>
        </div>
        <div class="col-6 text-end">
          <div class="col-12 mb-3">
            <label for="titleAr" class="form-label"> : العنوان </label>
            <input
              type="text"
              id="titleAr"
              v-model="project.titleAr"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="photo" class="form-label"> : الشعار </label>
            <input
              type="file"
              id="photo"
              @change="handleLogoUpload"
              class="form-control"
              accept="image/*"
              disabled
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.logoUrl" alt="Uploaded Photo" class="w-50" />
          </div>
          <br />
          القسم الأول
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionATitleAr" class="form-label">
              : عنوان القسم الأول
            </label>
            <input
              type="text"
              id="sectionATitleAr"
              v-model="project.sectionATitleAr"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionAImageAr" class="form-label">
              صورة للقسم الأول
            </label>
            <input
              type="file"
              id="sectionAImageAr"
              @change="handleImageSectionAUploadAr"
              class="form-control"
              accept="image/*"
            />
          </div>
          <div class="col-12 mb-3">
            <img :src="project.sectionAImageUrlAr" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionAFileAr" class="form-label">
              ملف القسم الأول
            </label>
            <input
              type="file"
              id="sectionAFileAr"
              @change="handleFileSectionAUploadAr"
              class="form-control"
              accept="*"
            />
          </div>

          <br />
          القسم الثاني
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionBTitleAr" class="form-label">
              عنوان القسم الثاني
            </label>
            <input
              type="text"
              id="sectionBTitleAr"
              v-model="project.sectionBTitleAr"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionBImageAr" class="form-label">
              صورة القسم الثاني
            </label>
            <input
              type="file"
              id="sectionBImageAr"
              @change="handleImageSectionBUploadAr"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.sectionBImageUrlAr" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionBFileAr" class="form-label">
              ملف القسم الثاني
            </label>
            <input
              type="file"
              id="sectionBFileAr"
              @change="handleFileSectionBUploadAr"
              class="form-control"
              accept="*"
            />
          </div>

          <br />
          القسم الثالث
          <hr />
          <div class="col-12 mb-3">
            <label for="sectionCTitleAr" class="form-label">
              عنوان القسم الثالث
            </label>
            <input
              type="text"
              id="sectionCTitleAr"
              v-model="project.sectionCTitleAr"
              class="form-control"
            />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionCImageAr" class="form-label">
              صورة للقسم الثالث
            </label>
            <input
              type="file"
              id="sectionCImageAr"
              @change="handleImageSectionCUploadAr"
              class="form-control"
              accept="image/*"
            />
          </div>

          <div class="col-12 mb-3">
            <img :src="project.sectionCImageUrlAr" alt="" class="w-50" />
          </div>

          <div class="col-12 mb-3">
            <label for="sectionCFileAr" class="form-label">
              ملف القسم الثالث
            </label>
            <input
              type="file"
              id="sectionCFileAr"
              @change="handleFileSectionCUploadAr"
              class="form-control"
              accept="*"
            />
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Enregistrer</button>
    </form>

    <br />
    <div class="row justify-content-center align-items-stratch">
      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionAImageUrl"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionATitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionBImageUrl"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionBTitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionCImageUrl"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionCTitle }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                Télécharger
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row justify-content-center align-items-stratch">
      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionAImageUrlAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionATitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionBImageUrlAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionBTitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="mx-1 card h-100 border-0 shadow">
          <div class="card-img-top h-65">
            <img
              lazyload
              :src="project.sectionCImageUrlAr"
              alt=""
              class="w-100 h-100 rounded-top"
            />
          </div>
          <div class="card-body text-center h-35">
            <div class="fw-bold text-center">
              {{ project.sectionCTitleAr }}
            </div>
            <div class="text-center">
              <button class="btn btn-warning text-white py-2 px-3 mt-4 mx-auto">
                تحميل
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  mounted() {
    this.$store.dispatch("project/find", this.$route.params.slug);
  },
  computed: {
    ...mapGetters("project", { project: "getProject" }),
  },
   methods: {
    save() {
      const formData = new FormData();

      // Append project title and titleAr
      formData.append("slug", this.project.slug);
      formData.append("title", this.project.title);
      formData.append("titleAr", this.project.titleAr);

      // Append project logo
      if (this.project.logoUrl) {
        formData.append("logoEdited", this.project.logo);
      }

      // Append section A data
      formData.append("sectionATitle", this.project.sectionATitle);
      formData.append("sectionATitleAr", this.project.sectionATitleAr);

      if (this.project.sectionAImageUrl) {
        formData.append("sectionAImageEdited", this.project.sectionAImage);
      }
      if (this.project.sectionAImageUrlAr) {
        formData.append("sectionAImageEditedAr", this.project.sectionAImageAr);
      }
      if (this.project.sectionAFileUrl) {
        formData.append("sectionAFileEdited", this.project.sectionAFile);
      }
      if (this.project.sectionAFileUrlAr) {
        formData.append("sectionAFileEditedAr", this.project.sectionAFileAr);
      }

      // Append section B data
      formData.append("sectionBTitle", this.project.sectionBTitle);
      formData.append("sectionBTitleAr", this.project.sectionBTitleAr);

      if (this.project.sectionBImageUrl) {
        formData.append("sectionBImageEdited", this.project.sectionBImage);
      }
      if (this.project.sectionBImageUrlAr) {
        formData.append("sectionBImageEditedAr", this.project.sectionBImageAr);
      }
      if (this.project.sectionBFileUrl) {
        formData.append("sectionBFileEdited", this.project.sectionBFile);
      }
      if (this.project.sectionBFileUrlAr) {
        formData.append("sectionBFileEditedAr", this.project.sectionBFileAr);
      }

      // Append section C data
      formData.append("sectionCTitle", this.project.sectionCTitle);
      formData.append("sectionCTitleAr", this.project.sectionCTitleAr);
      if (this.project.sectionCImageUrl) {
        formData.append("sectionCImageEdited", this.project.sectionCImage);
      }
      if (this.project.sectionCImageUrlAr) {
        formData.append("sectionCImageEditedAr", this.project.sectionCImageAr);
      }
      if (this.project.sectionCFileUrl) {
        formData.append("sectionCFileEdited", this.project.sectionCFile);
      }
      if (this.project.sectionCFileUrlAr) {
        formData.append("sectionCFileEditedAr", this.project.sectionCFileAr);
      }

      this.$store.dispatch("project/update", formData);
    },

    handleLogoUpload(event) {
      let file = event.target.files[0];
      this.project.logo = file;
      this.project.logoUrl = URL.createObjectURL(file);
    },
    handleImageSectionAUpload(event) {
      let file = event.target.files[0];
      this.project.sectionAImage = file;
      this.project.sectionAImageUrl = URL.createObjectURL(file);
    },

    handleImageSectionBUpload(event) {
      let file = event.target.files[0];
      this.project.sectionBImage = file;
      this.project.sectionBImageUrl = URL.createObjectURL(file);
    },

    handleImageSectionBUpload(event) {
      let file = event.target.files[0];
      this.project.sectionBImage = file;
      this.project.sectionBImageUrl = URL.createObjectURL(file);
    },

    handleFileSectionAUpload(event) {
      let file = event.target.files[0];
      this.project.sectionAFile = file;
      this.project.sectionAFileUrl = URL.createObjectURL(file);
    },

    handleFileSectionBUpload(event) {
      let file = event.target.files[0];
      this.project.sectionBFile = file;
      this.project.sectionBFileUrl = URL.createObjectURL(file);
    },

    handleFileSectionCUpload(event) {
      let file = event.target.files[0];
      this.project.sectionCFile = file;
      this.project.sectionCFileUrl = URL.createObjectURL(file);
    },

    handleImageSectionCUpload(event) {
      let file = event.target.files[0];
      this.project.sectionCImage = file;
      this.project.sectionCImageUrl = URL.createObjectURL(file);
    },

    handleImageSectionAUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionAImageAr = file;
      this.project.sectionAImageUrlAr = URL.createObjectURL(file);
    },

    handleImageSectionBUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionBImageAr = file;
      this.project.sectionBImageUrlAr = URL.createObjectURL(file);
    },

    handleFileSectionAUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionAFileAr = file;
      this.project.sectionAFileUrlAr = URL.createObjectURL(file);
    },

    handleFileSectionBUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionBFile = file;
      this.project.sectionBFileUrlAr = URL.createObjectURL(file);
    },

    handleFileSectionCUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionCFile = file;
      this.project.sectionCFileUrlAr = URL.createObjectURL(file);
    },

    handleImageSectionCUploadAr(event) {
      let file = event.target.files[0];
      this.project.sectionCImageAr = file;
      this.project.sectionCImageUrlAr = URL.createObjectURL(file);
    },

    refresh() {
      this.$store.dispatch("project/find", this.$route.params.slug);
    },
  },
};
</script>
